import * as THREE from 'three';
// import Experience from '../../Experience/Experience';
import EventEmitter from '../../Experience/Utils/EventEmitter';
// import WebExperience from '../../Experience/WebExperience';
import Engine from '../../Experience/Utils/Engine';

export default class BaseElement extends EventEmitter {
  constructor(_name) {
    super()

    this.engine = new Engine()
    // this.experience = new WebExperience()
    this.time = this.engine.time

    this.name = _name

    this.element = new THREE.Group()
    this.element.name = this.name

    this.elementScaleUpdate = false
    this.elementScaleSpeed = 50
    this.elementScaleNew = 0

    this.scale = {
      updateScale: false,
      speed: 30,
      defaultScale: 0.01,
      currentScale: 0.01,
      newScale: 0
    }

    // this.setScale(0)

    // this.scene.add(this.element)

    
  }

addToScene(el){
  this.element.add(el)
}


setPosition(newX, newY, newZ) {
    this.setX(newX);
    this.setY(newY);
    this.setZ(newZ);
}
setX(newX) {
  this.element.position.x = newX;
}
setY(newY) {
  this.element.position.y = newY;
}
setZ(newZ) {
  this.element.position.z = newZ;
}

setScale(value, speed = 30) {
  // console.log(this.scale);
  // console.log(`BaseElement setScale ${value}`);
  // this.element.scale.set(value, value, value)

  this.scale.newScale = value;
  this.elementScaleNew = value;
  this.scale.speed = speed;
  this.elementScaleSpeed = speed
  this.scale.updateScale = true;

  this.elementScaleUpdate = true

  // console.log(this.scale);

}

setRotation(value){
  this.element.rotation.y = value * 0.0174533
}

//   hide() {
//     this.setScale(0, 100);
//   }

//   show() {
//     this.setScale(this.scale.defaultScale, 10);
//   }



//   }

//   cloneGltf(gltf) {
//     const clone = {
//       animations: gltf.animations,
//       scene: gltf.scene.clone(true)
//     };

//     const skinnedMeshes = {};
//     gltf.scene.traverse(node => {
//       if (node.isSkinnedMesh) {
//         skinnedMeshes[node.name] = node;
//       }
//     });

//     const cloneBones = {};
//     const cloneSkinnedMeshes = {};

//     clone.scene.traverse(node => {
//       if (node.isBone) {
//         cloneBones[node.name] = node;
//       }

//       if (node.isSkinnedMesh) {
//         cloneSkinnedMeshes[node.name] = node;
//       }
//     });

//     for (let name in skinnedMeshes) {
//       const skinnedMesh = skinnedMeshes[name];
//       const {skeleton} = skinnedMesh;
//       const cloneSkinnedMesh = cloneSkinnedMeshes[name];
//       const orderedCloneBones = [];
//       for (let i = 0; i < skeleton.bones.length; ++i) {
//         const cloneBone = cloneBones[skeleton.bones[i].name];
//         orderedCloneBones.push(cloneBone);
//       }
//       cloneSkinnedMesh.bind(
//         new THREE.Skeleton(orderedCloneBones, skeleton.boneInverses),
//         cloneSkinnedMesh.matrixWorld);
//     }

//     return clone;
//   }

  useTexture(texture, resources) {
    // console.log(texture);
    // console.log(this.model);

    // console.log(resources);
    // console.log(this.element);
    // this.element.material.map = eval('resources.items.'+ texture);

    // this.textures.color = eval('resources.items.'+ texture);
    // this.textures.color.colorSpace = THREE.SRGBColorSpace;

    let newTexture = eval('resources.items.'+ texture);
    let newMaterial = new THREE.MeshPhongMaterial( { map: newTexture, side: THREE.DoubleSide, transparent: true})

    this.element.traverse((child) => {
      if (child.isMesh) {
        // console.log(child.material.map);
        // console.log(this.textures.color);

        child.material = newMaterial;
      }
    });
    // this.textures.color.repeat.set(1.5, 1.5);
    // this.textures.color.wrapS = THREE.RepeatWrapping;
    // this.textures.color.wrapT = THREE.RepeatWrapping;
  }

//   useTexutreVideo(textureVideo) {

//     this.textures.textureVideo = eval(' this.resources.items.'+ textureVideo);


//     const videoContainer = document.createElement('div');
//     videoContainer.id = 'videoContainer';
//     // videoContainer.style.display = 'none';

//     let videoElement = document.createElement("video");
//     videoElement.id = this.textures.textureVideo.name;
//     // videoElement.src = 'urlToVideo.ogg';
//     videoElement.autoplay = true;
//     videoElement.loop = true;
//     // videoElement.playsInline = true;
//     videoElement.muted = true;
//     videoElement.crossOrigin = 'anonymous';

//     if (videoElement.canPlayType('video/mp4')) {
//       // videoElement.setAttribute('src', this.textures.textureVideo.path_mp4);
//       const srcElmp4 = document.createElement('source');
//       srcElmp4.src = this.textures.textureVideo.path_mp4;

//       videoElement.append(srcElmp4);
//     } else {
//       // videoElement.setAttribute('src', this.textures.textureVideo.path_ogv);
//       const srcElogv = document.createElement('source');
//       srcElogv.src = this.textures.textureVideo.path_ogv;

//       videoElement.append(srcElogv);
//     }

//     videoElement.setAttribute('width', '320');
//     // videoElement.setAttribute('height', '240');
//     // videoElement.setAttribute('controls', 'controls');
//     videoElement.autoplay = true;

//     // videoElement.style.position = 'absolute';
//     // videoElement.style.top = '-240px';

//     videoElement.style.display = 'none';

//     videoElement.play();


//     videoContainer.append(videoElement);
//     this.rFramework.container.prepend(videoContainer);

//     const vt = document.getElementById(this.textures.textureVideo.name);
//     const vTex = new THREE.VideoTexture(vt);
//     vTex.colorSpace = THREE.SRGBColorSpace;

//     const parameters = { color: 0xffffff, map: vTex };
//     const vMat = new THREE.MeshBasicMaterial(parameters);

//     // const parameters = { color: 0xffffff };
//     // const vMat = new THREE.MeshPhongMaterial({ color: 0xffffff, transparent: true, opacity: 0.8 });

//     // this.videoMesh.material=material;

//     console.log(this.textures.textureVideo);
//     console.log(this.rFramework.container);

//     this.model.traverse((child) => {
//       if (child.isMesh) {
//         // console.log(child.material.map);
//         // console.log(this.textures.color);
    
//         // child.material.map = vTex;
//         child.material = vMat;
//         // child.material.needsUpdate = true;

//         console.log(child);
//       }
//     });
//   }



    update(){
      // console.log(this.name);
      // update Scale
      if(this.scale.updateScale){ 
        // console.log('SCALE ######');
        // console.log(this.element.scale);
        // // console.log(this.scale.defaultScale);
        // // console.log(this.scale.currentScale);
        // console.log(this.scale.newScale);
        // console.log(this.element.scale.x);
        // console.log(this.scale);
        // // console.log(scalevalue);
        // console.log(this);
        // console.log('SCALE ######');

        // let scalevalue = (Math.sin(this.time.current/2000) + 2)

        // if(this.scale.newScale<0 || this.scale.newScale > 1000){
        //   this.scale.newScale = 10
        // }

        // console.log(this.element.scale.x);
        // console.log(this.scale.newScale);
        // console.log(this.elementScaleSpeed);
        

        let scalevalue = this.element.scale.x + ( this.elementScaleNew - this.element.scale.x) / this.elementScaleSpeed
        this.element.scale.set(scalevalue, scalevalue, scalevalue)
        this.scale.currentScale = this.element.scale.x
        if(Math.abs(this.element.scale.x - this.elementScaleNew) < 0.0001 ) {
            this.element.scale.set( this.elementScaleNew,  this.elementScaleNew,  this.elementScaleNew)
            this.scale.updateScale = false
        }

        // console.log(scalevalue);
        // console.log(this.scale.currentScale);
        

        // let scalevalue = this.element.scale.x +( this.scale.newScale - this.element.scale.x)/this.scale.speed
        // this.element.scale.set(scalevalue, scalevalue, scalevalue)
        // this.scale.currentScale = this.element.scale.x
        // if(Math.abs(this.element.scale.x - this.scale.newScale) < 0.0001 ) {
        //     this.element.scale.set( this.scale.newScale,  this.scale.newScale,  this.scale.newScale)
        //     this.scale.updateScale = false
        // }
      }
    }
}
