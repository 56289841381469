import Engine from "./Utils/Engine";
import EventEmitter from "./Utils/EventEmitter";
// import WebExperience from "./WebExperience";

export default class UserCam extends EventEmitter {
  constructor(container) {
    super()

    this.engine = new Engine()
    // this.experience = new WebExperience()
    this.sizes = this.engine.sizes
    this.dataReceived = false;


    this.cameraContainer = document.getElementById('webExperience');
    this.streaming = false;
    this.mediaStream = null;
    this.constraints = { 
      audio: false, 
      video: { 
        width: { min: 640, ideal: 1920 },
        height: { min: 400, ideal: 1080 },
        aspectRatio: { ideal: 1.7777777778 },
        facingMode: "environment"
      } 
    }; 

    // this.init()
  }

  hide(){
    this.video.style.display = 'none';

  }

  show(){
    this.video.style.display = 'flex';
    // this.video.style.position = 'absolute'
    // this.video.style.left = '0px';

  }

  async init() {

    // console.log('userCam init');

    this.video = document.createElement('video');


    this.video.style.display = 'none';
    this.video.setAttribute('webkit-playsinline', 'webkit-playsinline');
    this.video.setAttribute('playsinline', '');
    this.video.classList.add("userCam");


    this.cameraContainer.prepend(this.video);

    await this.getMediaStream(this.constraints);

    // if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
    //   navigator.mediaDevices
    //     .getUserMedia({
    //       video: {
    //         advanced: [
    //           {
    //             facingMode: 'environment',
    //           },
    //         ],
    //       },
    //       audio: false,
    //     })
    //     .then((stream) => {
    //       video.srcObject = stream;

    //       this.parentEl.dispatchEvent(
    //         new CustomEvent('AR_CAMERA_RESPONSE', {
    //           bubbles: true,
    //           detail: {
    //             src: null,
    //             ok: true,
    //             support: true,
    //           },
    //         })
    //       );

    //       video.addEventListener(
    //         'canplay',
    //         function (ev) {
    //           if (!this.streaming) {
    //             this.streaming = true;
    //           }
    //         },
    //         false
    //       );
    //     })
    //     .catch((error) => {
    //       this.parentEl.dispatchEvent(
    //         new CustomEvent('AR_CAMERA_RESPONSE', {
    //           bubbles: true,
    //           detail: {
    //             src: null,
    //             ok: false,
    //             support: true,
    //           },
    //         })
    //       );

    //       console.error(error);
    //     });
    // } else {
    //   this.parentEl.dispatchEvent(
    //     new CustomEvent('AR_CAMERA_RESPONSE', {
    //       bubbles: true,
    //       detail: {
    //         src: null,
    //         ok: false,
    //         support: false,
    //       },
    //     })
    //   );

    //   console.error('AR Camera: Browser not support media device feature');
    // }

    // console.log('userCam init end -- call resize');
    this.trigger('mediaReceived')
    this.dataReceived = true
    // this.resize()
    // this.show()
  }

  async getMediaStream(constraints) {
    try {
      this.mediaStream =  await navigator.mediaDevices.getUserMedia(constraints);
       
      this.video.srcObject = this.mediaStream;
      this.video.onloadedmetadata = (event) => {
        this.video.play();
        // console.log('loaded metadata');
      };
    } catch (err)  {    
      console.error(err.message);   
    }
  }

  resize(){

    if(!this.dataReceived){
      setTimeout(() => {
        this.resize()
      }, 500);
      return
    }

    let screenProportion = this.sizes.width/this.sizes.height
    let mediaProportion = this.mediaStream.getVideoTracks()[0].getSettings().width/this.mediaStream.getVideoTracks()[0].getSettings().height
    this.video.style.position = 'relative';
    this.video.style.left = '0px';
    this.video.style.top = '0px';

    // console.log(screenProportion);
    if(screenProportion<1){

      this.video.style.height = `${window.innerHeight}px`;
      this.video.style.left = '50%';
      this.video.style.transform= 'translateX(-50%)';
      this.video.style.width = `auto`;

    } else {
      // console.log('horizontal usercam');

      if(mediaProportion>screenProportion){
        // this.video.setAttribute('width', window.innerHeight*mediaProportion);
        // this.video.setAttribute('height', window.innerHeight);

        // this.video.style.width = window.innerHeight*mediaProportion;
        // this.video.style.height = window.innerHeight;

        // this.video.style.left = `-${(this.mediaStream.getVideoTracks()[0].getSettings().width - (window.innerHeight*mediaProportion))/2}px`

        // this.video.style.transform = 'translateY(10px)';
      } else {
          // this.video.setAttribute('width', window.innerWidth);
          // this.video.setAttribute('height', window.innerWidth/mediaProportion);    
      }

        // console.log(this.video.width);
        // console.log(this.video.height);
        // console.log(this.video);
  
        this.video.style.width = window.innerWidth;
        this.video.style.height = window.innerWidth/mediaProportion;

        this.video.style.width = `${window.innerWidth}px`;
        this.video.style.height = `auto`;
        this.video.style.top = '50%';
        this.video.style.transform= 'translateY(-50%)';
  
    
      // this.video.setAttribute('width', window.innerWidth);
      // this.video.setAttribute('height', window.innerWidth/mediaProportion);

      // this.video.style.transform = 'translateY(10px)';



    }

    // if(screenProportion<mediaProportion){
    //   //pantalla vertical
    //   // console.log('vertical usercam');


    //   this.video.setAttribute('width', window.innerWidth);
    //   this.video.setAttribute('height', window.innerWidth*mediaProportion);
    //   this.video.style.transform = 'translateY(10px)';

    // } else {
    //   //pantalla horizontal

    //   // console.log('horizontal usercam');
    //   this.video.setAttribute('width', window.innerWidth);
    //   this.video.setAttribute('height', window.innerWidth/mediaProportion);
    //   this.video.style.transform = 'translateY(-40%)';


    // }

    // this.video.style.position = 'relative';
    // this.video.style.left = '50%';
    // this.video.style.transform = 'translateX(-50%)';


    // this.video.style.top = '50%';
    // this.video.style.transform = 'translateY(-50%)';

    // this.video.style.transform = 'scaleX(-1)';
    // this.video.setAttribute('webkit-transform', ' scaleX(-1)');
    // this.video.setAttribute('transform', 'scaleX(-1)');


  }
}