import * as THREE from 'three';
import Environment from './Environment.js'
import Resources from '../Utils/Resources.js'
import stageSources from './StageSources.js'
import ModelElement from '../../Global/Elements/ModelElement.js';
import { gsap } from 'gsap'
import Engine from '../Utils/Engine.js';

export default class Stage
{
    constructor()
    {

      this.initiated = false;

      this.engine = new Engine();
      this.scene = this.engine.scene
      this.camera = this.engine.camera

        this.stageResources = new Resources(stageSources)
        this.stageObjects = new THREE.Group()

        this.resourcesLoaded = false
        
        this.stageResources.on('ready', () =>
        {
          
          // console.log(this.stageResources.items.cooler);

          // console.log(this.stageResources.items.environmentMapTexture);

            // this.stageEnvironment = new Environment(this.stageResources.items.environmentMapTexture)

            // this.showElements()

            this.pack03Animations = stageSources.find(source => source.name === "pack03")
            // this.coolerAnimations = stageSources.find(source => source.name === "cooler")
            // console.log(this.pack03Animations);
            // console.log(this.coolerAnimations);

            this.stageObjects.scale.x = 35
            this.stageObjects.scale.y = 35
            this.stageObjects.scale.z = 35

            // this.stageObjects.position.set(15, -20, 15)

            // var tl = gsap.timeline( {
            //     repeat:-1, 
            //   } ); 
            //   tl.to(this.stageObjects.scale, {
            //     duration: 10,
            //     x: 30,
            //     y: 30,
            //     z: 30,
            //     repeat: -1,
            //     yoyo: true,
            //     ease: "sine.inOut",
            //   });
            //   tl.to(this.stageObjects.position, {
            //     duration: 13,
            //     x: 20,
            //     y: -15,
            //     z: 10,
            //     repeat: -1,
            //     yoyo: true,
            //     ease: "expo.inOut",
            //   });
            //   tl.to(this.pack01.element.rotation, {
            //     duration: 20,
            //     y: Math.PI/12,
            //     repeat: -1,
            //     yoyo: true,
            //     ease: "sine.inOut",
            //   });
              // tl.to(this.pack01.element.scale, {
              //   duration: 16,
              //   x: 4,
              //   y: 4,
              //   z: 4,
              //   repeat: -1,
              //   yoyo: true,
              //   ease: "sine.inOut",
              // });

              this.resourcesLoaded = true
          

        })
    }


    update()
    {
        if(this.pack03) {
            this.pack03.update()
            this.pack03.updateAnim()
        }
        // if(this.cooler) {
        //     this.cooler.update()
        //     this.cooler.updateAnim()
        // }
        this.stageObjects.lookAt(this.camera.instance.position)

        // this.stageObjects.lookAt(this.experience.camera.instance.position)

    }

    hideScene(){
        this.scene.remove(this.stageObjects)
    }

    showScene(){
      if(!this.resourcesLoaded){
        setTimeout(() => {
          this.showScene()
        }, 500);
        return
      }
      this.scene.add(this.stageObjects)

      if(!this.initiated){
        this.showElements()
      }

    }


    addLemmons(){
      this.lemmons = new THREE.Group()
      this.lemmon = new ModelElement('pack02', this.stageResources.items.pack02)


      // this.lemmon.setPosition(Math.random()*10+10, Math.random()* 20 -10, Math.random()*30+30)

      let counter = 12;
      // console.log('counter');

      for (let i = 0; i < counter; i++) {
        // console.log('element');

          let el = this.lemmon.element.clone()
          
          el.position.x = Math.random()*12-6
          if(Math.abs(el.position.x)<2){
            el.position.x = el.position.x > 0 ? el.position.x + 2 : el.position.x -2;
          }

          // console.log(el.position.x);

          el.position.y = Math.random()*8-6

          el.position.z = Math.random()*12-6
          if(Math.abs(el.position.z)<2){
            el.position.z = el.position.z > 0 ? el.position.z + 2 : el.position.z -2;
          }

          let newScale = el.scale.y + Math.random()*0.2-0.1

          let origScale = 0
          let newOrigScale = el.scale.y

          

          let initScale = gsap.fromTo(el.scale, { x: origScale, y: origScale, z: origScale}, { x: newOrigScale,y: newOrigScale,z: newOrigScale, duration: 2+Math.random()*5, repeat: 0, delay: (Math.random()*6+6)});
          initScale.eventCallback("onComplete", ()=>{
          gsap.to(el.scale, {
            duration: Math.random()*8+4,
            x: newScale,
            y: newScale,
            z: newScale,
            repeat: -1,
            yoyo: true,
            ease: "sine.inOut",
          });
          })

          // gsap.fromTo(el.scale, { x: newOrigScale, y: newOrigScale, z: newOrigScale}, { x: newScale,y: newScale,z: newScale, duration: Math.random()*8+4, repeat: -1,yoyo: true, ease: "sine.inOut"});




          gsap.fromTo(el.rotation, { y: 0, x: 0 }, { y: Math.random() * Math.PI,x: Math.random() * Math.PI, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });



          this.lemmons.add(el)
      }



      this.stageObjects.add(this.lemmons)

    }

    showElements(){
      // console.log('show elements start');
      // console.log(this.resourcesLoaded);

      if(!this.resourcesLoaded) {
        setTimeout(() => {
          // console.log('timeout show elements ok');
          this.showElements()
        }, 500);
        return
      }

      // console.log('show elements ok');

      let i = 110;

      this.pack01 = new ModelElement('pack01', this.stageResources.items.pack01)

            this.pack01.element.traverse((child) =>
              {
                if(child instanceof THREE.Mesh)
                  {

                    // child.material.depthWrite = true
                    // child.material.depthTest = true

                    // console.log(child.name);
                    let currScale = child.scale.y
                    let newScale = currScale + Math.random() * .5 - .25

                    let origScale = 0
                    let newOrigScale = child.scale.y
                    gsap.fromTo(child.scale, { x: origScale, y: origScale, z: origScale}, { x: newOrigScale,y: newOrigScale,z: newOrigScale, duration: 5+Math.random()*5, repeat: 0, delay: (Math.random()*4+2)});

                    if(child.name.includes('typo')){
                      let currPosX = child.position.x
                      let newPosX = currPosX + Math.random() * .6 + .2
                      gsap.fromTo(child.position, { x: currPosX}, { x: newPosX, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                      gsap.fromTo(child.scale, { x: currScale, y: currScale, z: currScale}, { x: newScale,y: newScale,z: newScale, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                    }
                    if(child.name.includes('sea')){
                      let currPosY = child.position.y
                      let newPosY = currPosY + Math.random() * .8 - .4
                      gsap.fromTo(child.position, { y: currPosY}, { y: newPosY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                      let currRotY = child.rotation.y
                      let newRotY = currRotY + Math.random() * 1 - .5
                      gsap.fromTo(child.rotation, { y: currRotY}, { y: newRotY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                    
                      child.renderOrder = 100 + i;
                      i++

                    
                    }
                    if(child.name.includes('lemon')){
                      let currPosY = child.position.y
                      let newPosY = currPosY + Math.random() * .4 - .2
                      gsap.fromTo(child.position, { y: currPosY}, { y: newPosY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                      let currRotY = child.rotation.y
                      let newRotY = currRotY + Math.random() * 3 - 1.5
                      gsap.fromTo(child.rotation, { y: currRotY}, { y: newRotY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                    }
                    if(child.name.includes('leave')){
                      let currPosY = child.position.y
                      let newPosY = currPosY + Math.random() * .2 - .1
                      gsap.fromTo(child.position, { y: currPosY}, { y: newPosY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                      let currRotY = child.rotation.y
                      let newRotY = currRotY + Math.random() * 1 - .5
                      gsap.fromTo(child.rotation, { y: currRotY}, { y: newRotY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                    }
                    if(child.name.includes('bottle1')){
                      let currPosY = child.position.y
                      let newPosY = currPosY + Math.random() * .5 - .25
                      gsap.fromTo(child.position, { y: currPosY}, { y: newPosY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                      let currRot = child.rotation.y
                      let newRot = Math.random() * 3 - 1.5
                      gsap.fromTo(child.rotation, { y: currRot - newRot}, { y: currRot + newRot, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                    }
                    if(child.name === 'bottle007' || child.name === 'bottle008'){
                      let currPosY = child.position.y
                      let newPosY = currPosY + Math.random() * .5 - .25
                      gsap.fromTo(child.position, { y: currPosY}, { y: newPosY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                      let currRot = child.rotation.y
                      let newRot = Math.random() * 3 - 1.5
                      gsap.fromTo(child.rotation, { y: currRot - newRot}, { y: currRot + newRot, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                    }
                    if(child.name === 'freezer007'){
                      let currPosY = child.position.y
                      let newPosY = currPosY + Math.random() * 2 - 1
                      gsap.fromTo(child.position, { y: currPosY}, { y: newPosY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                      // let currRotY = child.rotation.y
                      // let newRotY = currRotY + Math.random() * 4 - 2
                      // gsap.fromTo(child.rotation, { y: currRotY}, { y: newRotY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                    }
                    if(child.name.includes('sand')){
                      let currPosY = child.position.y
                      let newPosY = currPosY + Math.random() * 2 - 1
                      gsap.fromTo(child.position, { y: currPosY}, { y: newPosY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });

                      child.renderOrder = 200 + i;
                      i++
  
                      // console.log(child.position);
                      // console.log(child.rotation);
                    }
                    if(child.name.includes('radio')){
                      let currRotY = child.rotation.y
                      let newRotY = currRotY + Math.random() * .5 - .25
                      gsap.fromTo(child.rotation, { y: currRotY}, { y: newRotY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                    }

                  }
              })

            this.stageObjects.add(this.pack01.element)

            this.addLemmons()



            this.pack03 = new ModelElement('pack03', this.stageResources.items.pack03, this.pack03Animations.animations)

            this.pack03.element.traverse((child) =>
              {
                if(child instanceof THREE.Mesh)
                  {

                    // child.material.depthWrite = true
                    // child.material.depthTest = true

                    // console.log(child.name);
                  //   let currScale = child.scale.y
                  //   let newScale = currScale + Math.random() * .5 - .25

                  //   let origScale = 0
                  //   let newOrigScale = child.scale.y
                  //   gsap.fromTo(child.scale, { x: origScale, y: origScale, z: origScale}, { x: newOrigScale,y: newOrigScale,z: newOrigScale, duration: 5+Math.random()*5, repeat: 0, delay: (Math.random()*4+2)});

                  if(child.name.includes('pants002')){

                    child.material.envMapIntensity = 5

                    // let newPant = new THREE.Mesh( child.geometry, child.material );
                    // // let newPant = child.clone()
                    // newPant.position.set(child.position.x+2, child.position.y-1, child.position.z+2);
                    // // console.log(child.material);
                    // let nmat = new THREE.MeshStandardMaterial({
                    //   // emissiveMap: child.material.map,
                    //   // emissiveIntensity: 0.3
                    //   color: 0x888888,
                    //   // map: child.material.map,
                    //   toneMapped: true,
                    //   roughness: 0.5,
                    //   metalness: 0.5

                    // })
                    // // console.log(nmat);

                    // newPant.material = nmat
                    // this.scene.add(newPant)
                  }
                  //   if(child.name.includes('sea')){
                  //     let currPosY = child.position.y
                  //     let newPosY = currPosY + Math.random() * .8 - .4
                  //     gsap.fromTo(child.position, { y: currPosY}, { y: newPosY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                  //     let currRotY = child.rotation.y
                  //     let newRotY = currRotY + Math.random() * 1 - .5
                  //     gsap.fromTo(child.rotation, { y: currRotY}, { y: newRotY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                    
                  //     child.renderOrder = 100 + i;
                  //     i++

                    
                  //   }
                  //   if(child.name.includes('lemon')){
                  //     let currPosY = child.position.y
                  //     let newPosY = currPosY + Math.random() * .4 - .2
                  //     gsap.fromTo(child.position, { y: currPosY}, { y: newPosY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                  //     let currRotY = child.rotation.y
                  //     let newRotY = currRotY + Math.random() * 3 - 1.5
                  //     gsap.fromTo(child.rotation, { y: currRotY}, { y: newRotY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                  //   }
                  //   if(child.name.includes('leave')){
                  //     let currPosY = child.position.y
                  //     let newPosY = currPosY + Math.random() * .2 - .1
                  //     gsap.fromTo(child.position, { y: currPosY}, { y: newPosY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                  //     let currRotY = child.rotation.y
                  //     let newRotY = currRotY + Math.random() * 1 - .5
                  //     gsap.fromTo(child.rotation, { y: currRotY}, { y: newRotY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                  //   }
                  //   if(child.name.includes('bottle1')){
                  //     let currPosY = child.position.y
                  //     let newPosY = currPosY + Math.random() * .5 - .25
                  //     gsap.fromTo(child.position, { y: currPosY}, { y: newPosY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                  //     let currRot = child.rotation.y
                  //     let newRot = Math.random() * 3 - 1.5
                  //     gsap.fromTo(child.rotation, { y: currRot - newRot}, { y: currRot + newRot, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                  //   }
                  //   if(child.name === 'bottle007' || child.name === 'bottle008'){
                  //     let currPosY = child.position.y
                  //     let newPosY = currPosY + Math.random() * .5 - .25
                  //     gsap.fromTo(child.position, { y: currPosY}, { y: newPosY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                  //     let currRot = child.rotation.y
                  //     let newRot = Math.random() * 3 - 1.5
                  //     gsap.fromTo(child.rotation, { y: currRot - newRot}, { y: currRot + newRot, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                  //   }
                  //   if(child.name === 'freezer007'){
                  //     let currPosY = child.position.y
                  //     let newPosY = currPosY + Math.random() * 2 - 1
                  //     gsap.fromTo(child.position, { y: currPosY}, { y: newPosY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                  //     // let currRotY = child.rotation.y
                  //     // let newRotY = currRotY + Math.random() * 4 - 2
                  //     // gsap.fromTo(child.rotation, { y: currRotY}, { y: newRotY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                  //   }
                  //   if(child.name.includes('sand')){
                  //     let currPosY = child.position.y
                  //     let newPosY = currPosY + Math.random() * 2 - 1
                  //     gsap.fromTo(child.position, { y: currPosY}, { y: newPosY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });

                  //     child.renderOrder = 200 + i;
                  //     i++
  
                  //     // console.log(child.position);
                  //     // console.log(child.rotation);
                  //   }
                  //   if(child.name.includes('radio')){
                  //     let currRotY = child.rotation.y
                  //     let newRotY = currRotY + Math.random() * .5 - .25
                  //     gsap.fromTo(child.rotation, { y: currRotY}, { y: newRotY, duration: 5+Math.random()*5, repeat: -1, yoyo: true, ease: "sine.inOut", });
                  //   }

                  }
              })


            this.pack03.playAnimation('floating')

            let origScale = 0
            let newOrigScale = this.pack03.element.scale.y
            gsap.fromTo(this.pack03.element.scale, { x: origScale, y: origScale, z: origScale}, { x: newOrigScale,y: newOrigScale,z: newOrigScale, duration: 5+Math.random()*5, repeat: 0, delay: 6});

            this.stageObjects.add(this.pack03.element)

            // this.cooler = new ModelElement('cooler', this.stageResources.items.cooler, this.coolerAnimations.animations)
            // console.log(this.cooler);
            // this.cooler.playAnimation('coolerTapaAction')

            // let newCoolerScale = this.cooler.element.scale.y
            // gsap.fromTo(this.cooler.element.scale, { x: origScale, y: origScale, z: origScale}, { x: newCoolerScale,y: newCoolerScale,z: newCoolerScale, duration: 5+Math.random()*5, repeat: 0, delay: 6});

            // this.stageObjects.add(this.cooler.element)


            this.stageEnvironment = new Environment(this.stageResources.items.environmentMapTexture)  
            
            
            this.initiated = true

    }

}