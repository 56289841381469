import * as THREE from 'three'
import BaseElement from './BaseElement';

export default class ModelElement extends BaseElement {
  constructor(_name, _model, _animations = null) {
    super(_name);

    this.hasAnimations = false
    // console.log(_model);

    if(_model != null){
        // console.log('model ex');
        // this.resource = eval('this.resources.items.'+ _model)
        this.resource = _model

        // console.log(this.resources);
        // this.resourceAnimations = eval('this.resources.itemAnimations.'+ _model)
        this.resourceAnimations = _animations
        // console.log(this.resourceAnimations);

        // console.log(this.resourceAnimations);
        // this.resource = this.resources.items.foxModel

        // console.log(this.resource);

        this.setElement(this.resource)
        
        // console.log(this.resourceAnimations);

        if(_animations!= null){
          this.hasAnimations = true
          // console.log('has animationssssss');
          // console.log(_animations);
          // console.log(this.resourceAnimations);
          if(this.resourceAnimations.length>0){
            this.setAnimation()
          }
        }



    }

    // this.setGeometry(new THREE.BoxGeometry(1, 1, 1))
    // // this.setTextures()
    // this.setMaterial(new THREE.MeshStandardMaterial({
    //   }))
    // this.setMesh()


    // this.setElement('box')
  }




//   addToScene(element){
//     this.scene.add(element)

// }

setElement(model=null){
    if(model != null){
        this.setModel(model)
    }
}




// setColorTexture(texture){
//     this.textures.color = eval(' this.resources.items.'+ texture)
//     this.textures.color.colorSpace = THREE.SRGBColorSpace
//     this.textures.color.repeat.set(1.5, 1.5)
//     this.textures.color.wrapS = THREE.RepeatWrapping
//     this.textures.color.wrapT = THREE.RepeatWrapping
// }
// setNormalTexture(texture){
//     this.textures.normal = eval(' this.resources.items.'+ texture)
//     this.textures.normal.colorSpace = THREE.SRGBColorSpace
//     this.textures.normal.repeat.set(1.5, 1.5)
//     this.textures.normal.wrapS = THREE.RepeatWrapping
//     this.textures.normal.wrapT = THREE.RepeatWrapping
// }

// setMaterial(material)
// {
//     // this.material = new THREE.MeshStandardMaterial({
//     //     map: this.textures.color,
//     //     normalMap: this.textures.normal
//     // })
//     this.material = material
// }



setModel(loadedModel)
{
    this.clonedresource = this.cloneGltf(loadedModel)
    this.model = this.clonedresource.scene
    this.model.traverse((child) =>
    {
        if(child instanceof THREE.Mesh)
        {
          // console.log(child.name);
          if(child.name === 'freezer007'){
            // console.log(child.material);

          }
          if(child.name === 'freezer007_1'){
            // console.log(child.material);
          }
            // child.castShadow = true
            // child.material.side = THREE.DoubleSide
        }
    })
    // this.setScale(this.scale.defaultScale)

    // console.log(this.model);

    // this.setScale(0)
    // console.log(this.model);

    this.addToScene(this.model)
}

setAnimation()
{
    this.animation = {}
    
    // Mixer
    this.animation.mixer = new THREE.AnimationMixer(this.model)
    
    // Actions
    this.animation.actions = {}
    this.animation.actionList = []

    // console.log(this.resourceAnimations);

    
    this.resourceAnimations.forEach( anim => {
      // console.log(anim);
        // console.log(this.clonedresource.animations);
        // console.log(this.clonedresource.animations.find(({ name }) => name === animName));
        let animTo = this.clonedresource.animations.find(({ name }) => name === anim)

        this.animation.actions[anim] = this.animation.mixer.clipAction(animTo)
        this.animation.mixer.clipAction(animTo).name = anim
        this.animation.actionList.push(this.animation.mixer.clipAction(animTo))

        // this.animation.actions[anim.name] = this.animation.mixer.clipAction(anim)
        // this.animation.mixer.clipAction(anim).name = anim.name
        // this.animation.actionList.push(this.animation.mixer.clipAction(anim))

        // const clip = this.animations.find((clip) => clip.name === animName);
        // const action = mixer.clipAction(clip);
        // console.log(action);
    })


    // this.clonedresource.animations.forEach(anim => {
    //     this.animation.actions[anim.name] = this.animation.mixer.clipAction(anim)
    //     this.animation.mixer.clipAction(anim).name = anim.name
    //     this.animation.actionList.push(this.animation.mixer.clipAction(anim))
    // });

    // console.log(this.resource);

    // console.log(this.animation.actionList);
    // console.log(this.animation.actionList.Survey);

    // console.log(this.animation.actionList.indexOf("Survey"));

    // this.animation.actions.current = this.animation.actions.Survey

    // this.animation.actions.current = this.animation.actionList.find(({ name }) => name === "Cylinder.002Action.001")
    this.animation.actions.current = this.animation.actionList[0]

    // console.log(this.animation.actionList.find(({ name }) => name === "Survey"));
    // this.animation.actions.current.play()


    // Play the action
    this.animation.play = (newName) =>
    {
        // const newAction = this.animation.actionList[name]
        const newAction = this.animation.actionList.find(({ name }) => name === newName)
        const oldAction = this.animation.actions.current


        // console.log(newName);
        // console.log(newAction);
        // newAction.loop = THREE.LoopOnce
        newAction.loop = THREE.LoopPingPong
        // console.log(newAction);
        // console.log('#############################################################################');
        newAction.reset()
        newAction.play()
        newAction.crossFadeFrom(oldAction, 1)

        this.animation.actions.current = newAction
    }
    this.animation.stop = () => {
        const oldAction = this.animation.actions.current
        // console.log(oldAction);
        oldAction.fadeOut(1)
    }


    // Debug
    // if(this.debug.active)
    // {

    //     const debugObject = {
    //         // playSurvey: () => { this.animation.play('Survey') },
    //         // playWalk: () => { this.animation.play('Walk') },
    //         // playRun: () => { this.animation.play('Run') }
    //     }

    //     // console.log("this.animation.animations");
    //     // console.log(this.animation);
    //     this.animation.actionList.forEach(al =>{
    //         // console.log(al.name);
    //         debugObject['play'+al.name] = () => { this.animation.play(al.name) }
    //         this.debugFolder.add(debugObject, 'play'+al.name)
    //     })
    //     debugObject['stop'] = () => { this.animation.stop() }
    //     this.debugFolder.add(debugObject, 'stop')
    //     debugObject['show'] = () => { this.show() }
    //     this.debugFolder.add(debugObject, 'show')
    //     debugObject['hide'] = () => { this.hide() }
    //     this.debugFolder.add(debugObject, 'hide')

    //     // const debugObject = {
    //     //     // playSurvey: () => { this.animation.play('Survey') },
    //     //     // playWalk: () => { this.animation.play('Walk') },
    //     //     // playRun: () => { this.animation.play('Run') }
    //     // }

    //     // console.log(debugObject);
    //     // this.debugFolder.add(debugObject, 'playSurvey')
    //     // this.debugFolder.add(debugObject, 'playWalk')
    //     // this.debugFolder.add(debugObject, 'playRun')
    // }
}

// setPosition(newX, newY, newZ){
//     this.setX(newX)
//     this.setY(newY)
//     this.setZ(newZ)
// }
// setX(newX){
//     this.model.position.x = newX
// }
// setY(newY){
//     this.model.position.y = newY
// }
// setZ(newZ){
//     this.model.position.z = newZ
// }

// setScale(value, speed = 30){
//     this.scale.newScale=value
//     this.scale.updateScale = true
//     this.scale.speed = speed
//     // this.model.scale.set(value,value,value)
//     // console.log(this.time);
//     // this.model.scale.set(this.)
// }

// hide(){
//     this.setScale(0, 10)
// }
// show(){
//     this.setScale(this.scale.defaultScale, 10)
// }

playAnimation(animName){
  this.animation.play(animName)
}

updateAnim()
{
  if(this.hasAnimations){
    this.animation.mixer.update(this.time.delta * 0.0005)

  }
}


cloneGltf(gltf) {
    const clone = {
      animations: gltf.animations,
      scene: gltf.scene.clone(true)
    };
  
    const skinnedMeshes = {};
  
    gltf.scene.traverse(node => {
      if (node.isSkinnedMesh) {
        skinnedMeshes[node.name] = node;
      }
    });
  
    const cloneBones = {};
    const cloneSkinnedMeshes = {};
  
    clone.scene.traverse(node => {
      if (node.isBone) {
        cloneBones[node.name] = node;
      }
  
      if (node.isSkinnedMesh) {
        cloneSkinnedMeshes[node.name] = node;
      }
    });
  
    for (let name in skinnedMeshes) {
      const skinnedMesh = skinnedMeshes[name];
      const skeleton = skinnedMesh.skeleton;
      const cloneSkinnedMesh = cloneSkinnedMeshes[name];
  
      const orderedCloneBones = [];
  
      for (let i = 0; i < skeleton.bones.length; ++i) {
        const cloneBone = cloneBones[skeleton.bones[i].name];
        orderedCloneBones.push(cloneBone);
      }
  
      cloneSkinnedMesh.bind(
          new THREE.Skeleton(orderedCloneBones, skeleton.boneInverses),
          cloneSkinnedMesh.matrixWorld);
    }
  
    return clone;
  }
}
