import { gsap } from 'gsap';

export default class SoundButton {
    constructor() {
        this.button = document.getElementById('soundButton');
        this.audio = new Audio('/audio/bw.mp3');
        this.audio.loop = true;
        this.isActive = false;

        this.button.addEventListener('click', this.toggleSound.bind(this));
    }

    toggleSound() {
        if (this.isActive) {
            this.mute();
        } else {
            this.unmute();
        }
    }

    mute() {
        let target = this
        gsap.fromTo(this.audio, {volume: 1}, { volume: 0, duration: 1, ease: 'power2.out', onComplete: ()=>{ 
            target.isActive = false;
            target.audio.pause(); 
            target.button.classList.add('sound--muted');
        }});

    }
    
    fastMute() {
        this.isActive = false;
        this.audio.pause(); 
        this.button.classList.add('sound--muted');
    }

    unmute() {
        this.isActive = true;
        this.audio.play();
        this.button.classList.remove('sound--muted');
        gsap.fromTo(this.audio, {volume: 0}, { volume: 1, duration: 1, ease: 'power2.out' });

    }

    show() {
        this.button.classList.add('sound--active');
        this.unmute();
    }

    hide() {
        this.button.classList.remove('sound--active');
        this.mute();
    }

    getElement() {
        return this.button;
    }
}