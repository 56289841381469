import EventEmitter from './EventEmitter.js'

export default class Sizes extends EventEmitter
{
    constructor()
    {
        super()

        // Setup
        this.width = window.innerWidth
        this.height = window.innerHeight
        this.pixelRatio = Math.min(window.devicePixelRatio, 2)

        let target = this

        // Resize event
        window.addEventListener('resize', () =>
        {

            this.updateSizes()
            // console.log(window.innerHeight);

            this.trigger('resize')
        })
    }

    updateSizes(){
        this.width = window.innerWidth
        this.height = window.innerHeight
        this.pixelRatio = Math.min(window.devicePixelRatio, 2)

    }
}