import { gsap } from 'gsap';
import EventEmitter from '../Utils/EventEmitter';
import SoundButton from '../SoundButton';

export default class QRDesktop extends EventEmitter {
    constructor() {
        super();

        this.page = document.getElementById('qrDesktop');
        this.soundButton = new SoundButton();

        this.initAnimations();
    }

    show() {
        this.page.classList.add('section--active');
        console.log('Show section QR Desktop');

        this.soundButton.show();
        this.playAnimation();
        this.videoQr = this.page.querySelector('.videoQr')
        this.videoQr.play()
        
    }

    hide() {
        this.page.classList.remove('section--active');
        console.log('Hide section QR Desktop');
    }

    initAnimations() {
        this.tl = gsap.timeline({
            paused: true,
            defaults: { duration: 1, ease: 'power2.in' },
            onComplete: this.onAnimationComplete.bind(this)
        });

        this.tl
            .from(this.page.querySelector('.logo'), {
                duration: 1,
                ease: 'power2.out',
                opacity: 0,
            })
            .from(this.soundButton.getElement(), {
                duration: 1,
                ease: 'power2.out',
                opacity: 0,
            }, 0)
            .from(this.page.querySelector('.content__phone'), {
                duration: 1,
                ease: 'power2.out',
                opacity: 0,
                x: 20
            }, "-=0.5")
            .from(this.page.querySelector('.content__text'), {
                duration: 1,
                ease: 'power2.out',
                opacity: 0,
                x: 20
            }, "-=0.75")
            // .from(this.page.querySelector('.legal'), {
            //     duration: 0.5,
            //     ease: 'power2.out',
            //     opacity: 0,
            //     y: 15
            // }, "-=0.2")
            .from(this.page.querySelectorAll('.social>li'), {
                duration: 0.5,
                ease: 'power2.out',
                opacity: 0,
                y: 15,
                stagger: 0.25
            })
    }

    onAnimationComplete() {
        console.log('Animation complete');
    }

    playAnimation() {
        this.tl.play();
    }
}
