import * as THREE from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { HorizontalBlurShader } from 'three/examples/jsm/shaders/HorizontalBlurShader.js';
import { VerticalBlurShader } from 'three/examples/jsm/shaders/VerticalBlurShader.js';
import Engine from './Utils/Engine.js';



export default class Renderer
{
    constructor()
    {
        this.engine = new Engine()
        // this.experience = new WebExperience()
        this.canvas = this.engine.canvas
        this.sizes = this.engine.sizes
        this.scene = this.engine.scene
        this.camera = this.engine.camera


        this.setInstance()
    }

    setInstance()
    {
        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true,
            alpha: true,
        })
        this.instance.toneMapping = THREE.CineonToneMapping
        // this.instance.toneMapping = THREE.ReinhardToneMapping

        this.instance.toneMappingExposure = 1.5
        this.instance.shadowMap.enabled = false
        this.instance.shadowMap.type = THREE.PCFSoftShadowMap
        this.instance.setClearColor('#ff0000', 0)
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(this.sizes.pixelRatio)


        this.composer = new EffectComposer(this.instance);

        this.renderPass = new RenderPass(this.scene, this.camera.instance);
        this.composer.addPass(this.renderPass);


        this.hblur = new ShaderPass( HorizontalBlurShader );
        // this.composer.addPass( this.hblur );

        this.vblur = new ShaderPass( VerticalBlurShader );
// set this shader pass to render to screen so we can see the effects
        this.vblur.renderToScreen = true;
        // this.composer.addPass( this.vblur );



  this.outputPass = new OutputPass();
  this.composer.addPass(this.outputPass);


        // this.instance.autoClear = false;


    }

    resize()
    {
        // console.log(this.sizes.height);
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(this.sizes.pixelRatio)
    }

    update()
    {

        // console.log(1+Math.sin(this.experience.time.current / 100));

        // let pr = Math.max(.2, (1+Math.sin(this.experience.time.current / 1000))/2)
        // pr = Math.min(pr, this.sizes.pixelRatio)

        // console.log(pr);
        
        // this.instance.setPixelRatio(.1)
        
        // this.instance.render(this.scene, this.camera.instance)

        
        this.composer.render(0.1)


    }
}