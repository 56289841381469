export default [
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            'textures/environmentMap/px.jpg',
            'textures/environmentMap/nx.jpg',
            'textures/environmentMap/py.jpg',
            'textures/environmentMap/ny.jpg',
            'textures/environmentMap/pz.jpg',
            'textures/environmentMap/nz.jpg'
        ]
    },
    // {
    //     name: 'coronacap',
    //     type: 'texture',
    //     path: 'textures/test/coronacap.png'
    // },
    // {
    //     name: 'grassColorTexture',
    //     type: 'texture',
    //     path: 'textures/dirt/color.jpg'
    // },
    // {
    //     name: 'grassNormalTexture',
    //     type: 'texture',
    //     path: 'textures/dirt/normal.jpg'
    // },
    // {
    //     name: 'panoramaTestNoise',
    //     type: 'texture',
    //     path: 'textures/panorama/panorama_test_noise.jpg'
    // },
    {
        name: 'pack01',
        type: 'gltfModel',
        // animations: ['LemonAction'],
        path: 'models/0820_packAR.glb'
    },
    {
        name: 'pack02',
        type: 'gltfModel',
        // animations: ['LemonAction'],
        path: 'models/lemon.glb'
    },
    {
        name: 'pack03',
        type: 'gltfModel',
        animations: ['floating.001', 'floating'],
        path: 'models/0820_pplumaAR.glb'
    },
    // {
    //     name: 'cooler',
    //     type: 'gltfModel',
    //     animations: ['coolerTapaAction'],
    //     path: 'models/cooler.glb'
    // },
]