import * as THREE from 'three'
import Engine from './Utils/Engine.js';

export default class Camera
{
    constructor()
    {
        this.engine = new Engine()
        // this.experience = new WebExperience()
        this.sizes = this.engine.sizes
        this.scene = this.engine.scene
        this.canvas = this.engine.canvas

        this.setInstance()
        // this.setControls()
        // this.deactivateControls()

    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(80, this.sizes.width / this.sizes.height, 0.001, 1000)
        this.instance.position.set(1, 0, 0)
        // this.instance.lookAt(new THREE.Vector3(0,0,2))

        this.instance.rotation.y = Math.PI/2
        this.scene.add(this.instance)
    }

    // setControls()
    // {
    //     this.controls = new OrbitControls(this.instance, this.canvas)
    //     this.controls.enablePan = false;
    //     this.controls.enableZoom = false;
    //     this.controls.enableDamping = true

    // }

    activateControls(){
        // this.controls.enabled = true

    }
    // deactivateControls(){
    //     // this.controls.enabled = false

    // }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update()
    {
        // this.controls.update()
        // this.instance.fov =  60 + Math.sin(this.experience.time.current / 1000) * 30
        // this.instance.updateProjectionMatrix()
    }

   
}