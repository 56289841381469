import EventEmitter from "./EventEmitter";

export default class SitePreloader extends EventEmitter {
    constructor(resources, onComplete) {
        super()
        
        this.resources = resources;
        this.onComplete = onComplete;
        this.loadedResources = 0;
        this.preload();
    }

    preload() {
        this.resources.forEach(resource => {
            if (resource.endsWith('.mp4')) {
                this.preloadVideo(resource);
            } else {
                this.preloadImage(resource);
            }
        });
    }

    resourceLoaded() {
        this.loadedResources++;
        if (this.loadedResources === this.resources.length) {
            this.onComplete();
        }
    }

    preloadImage(url) {
        const img = new Image();
        img.src = url;
        img.onload = () => this.resourceLoaded();
        img.onerror = () => this.resourceLoaded();
    }

    preloadVideo(url) {
        const video = document.createElement('video');
        video.src = url;
        video.onloadeddata = () => this.resourceLoaded();
        video.onerror = () => this.resourceLoaded();
    }
}