import * as THREE from 'three'
import EventEmitter from './EventEmitter.js'
import Sizes from './Sizes.js'
import Time from './Time.js'
import Camera from '../Camera.js'
import Renderer from '../Renderer.js'

let instance = null

export default class Engine extends EventEmitter
{
    constructor()
    {
        super()

        // Singleton
        if(instance)
            {
                return instance
            }
        instance = this

        this.canvas = document.getElementById('canvasXR')
            
        // Utils
        this.sizes = new Sizes()
        this.time = new Time()

        // Scene Cam & Renderer
        this.scene = new THREE.Scene()
        this.camera = new Camera()
        this.renderer = new Renderer()
        
        // this.camera = new Camera()


        // this.sizes = new Sizes()
        // this.time = new Time()
        // this.scene = new THREE.Scene()

        // this.camera = new Camera()
        // this.renderer = new Renderer()

        
    }
}