import * as THREE from 'three'
import Engine from '../Utils/Engine.js'

export default class Environment
{
    constructor(_envMap)
    {
        console.log('ENVIRONMENT CONSTRUCTOR');
        console.log(_envMap);
        this.engine = new Engine();

        // this.experience = new WebExperience()
        this.scene = this.engine.scene
        // this.resources = this.experience.stage.stageResources
        // this.debug = this.experience.debug
        
        // // Debug
        // if(this.debug.active)
        // {
        //     this.debugFolder = this.debug.ui.addFolder('environment')
        // }

        this.setSunLight()
        this.setEnvironmentMap(_envMap)
    }

    setSunLight()
    {
        this.sunLight = new THREE.DirectionalLight('#ffffff', 1)
        this.sunLight.castShadow = false
        this.sunLight.shadow.camera.far = 15
        this.sunLight.shadow.mapSize.set(1024, 1024)
        this.sunLight.shadow.normalBias = 0.05
        this.sunLight.position.set(-3.5, 5, - 2)
        // this.scene.add(this.sunLight)

        this.pointLight = new THREE.HemisphereLight( 0xeeeed9, 0x827463, 2 );
        this.pointLight.castShadow = false

        this.pointLight.position.set(0, 10, -2)

        this.scene.add(this.pointLight)

        // this.centerLight = new THREE.PointLight(0xFF0000, 10, 0, 1)
        // this.centerLight.position.set(0, 0, 0)
        // this.centerLight.castShadow = false

        // this.scene.add(this.centerLight)


        // // Debug
        // if(this.debug.active)
        // {
        //     this.debugFolder
        //         .add(this.sunLight, 'intensity')
        //         .name('sunLightIntensity')
        //         .min(0)
        //         .max(10)
        //         .step(0.001)
            
        //     this.debugFolder
        //         .add(this.sunLight.position, 'x')
        //         .name('sunLightX')
        //         .min(- 5)
        //         .max(5)
        //         .step(0.001)
            
        //     this.debugFolder
        //         .add(this.sunLight.position, 'y')
        //         .name('sunLightY')
        //         .min(- 5)
        //         .max(5)
        //         .step(0.001)
            
        //     this.debugFolder
        //         .add(this.sunLight.position, 'z')
        //         .name('sunLightZ')
        //         .min(- 5)
        //         .max(5)
        //         .step(0.001)
        // }
    }

    setEnvironmentMap(_envMap)
    {
        console.log(_envMap);
        this.environmentMap = {}
        this.environmentMap.intensity = 0.6
        this.environmentMap.texture = _envMap
        this.environmentMap.texture.colorSpace = THREE.SRGBColorSpace
        
        this.scene.environment = this.environmentMap.texture

        this.environmentMap.updateMaterials = () =>
        {
            this.scene.traverse((child) =>
            {
                if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
                {
                    child.material.envMap = this.environmentMap.texture
                    // child.material.envMapIntensity = this.environmentMap.intensity
                    child.material.needsUpdate = true
                }
            })
        }
        this.environmentMap.updateMaterials()

        // Debug
        // if(this.debug.active)
        // {
        //     this.debugFolder
        //         .add(this.environmentMap, 'intensity')
        //         .name('envMapIntensity')
        //         .min(0)
        //         .max(4)
        //         .step(0.001)
        //         .onChange(this.environmentMap.updateMaterials)
        // }
    }
}