import * as THREE from 'three'
import { DeviceOrientationControls } from './Utils/DeviceOrientationControls';
import isMobile from 'is-mobile';
import Engine from './Utils/Engine';



export default class CameraControl {
    mouse = new THREE.Vector2();

    constructor(){

        this.engine = new Engine()
        this.sizes = this.engine.sizes
        this.camera = this.engine.camera

        this.active = false

        let target = this

        this.docGranted = false

        // console.log(isMobile());

        if (isMobile()) {
            // console.log('is mobile');
        }

        this.engine.camera.instance.rotation.reorder( 'YXZ' );

        if ( typeof( DeviceMotionEvent ) !== "undefined" && typeof( DeviceMotionEvent.requestPermission ) === "function" ) {
            // (optional) Do something before API request prompt.
            DeviceMotionEvent.requestPermission()
                .then( response => {
                // (optional) Do something after API prompt dismissed.
                if ( response == "granted" ) {
                    target.docGranted = true

                    target.activateControls()
                }
            })
                .catch( console.error )
        } else {
            // alert( "DeviceMotionEvent is not defined" );
            // console.log('not defined');
            target.docGranted = true
            target.activateControls()
            
        }
    


        document.addEventListener( 'mousemove', (event) => {
            // console.log('touch disabled');
            target.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
            target.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

            target.updateMouseMove();

        }, false);






        // if (window.DeviceOrientationEvent) {
        //     window.addEventListener('deviceorientation', function (eventData) {
        
        
        //         var tiltX = Math.round(eventData.gamma * 2 );
        
        
        //         var tiltY =  Math.round(eventData.beta * 2);
        
        //         target.deviceOrientationHandler(tiltX,tiltY);
        
        //     }, false);
        // }


        // console.log(renderer);
        // renderer.domElement.addEventListener('mousemove', event => {
        //     console.log('move');

        // })    

        // renderer.domElement.addEventListener('mousedown', () => { this.press = true })
        // renderer.domElement.addEventListener('mouseup', () => { this.press = false })
        // renderer.domElement.addEventListener('mouseleave', () => { this.press = false })



        // document.addEventListener('keyup', event => {
        //     if(event.key == 'Shift'){
        //         this.zoomMode = false
        //     }
        // })


    }

    updateMouseMove( ) {
        if(!this.docGranted){
            if(Math.abs(this.mouse.x) > 0.3 && Math.abs(this.mouse.x) < 0.9){
                // let acceleration = 0.3 - Math.abs(0.6 - Math.abs(this.mouse.x))


            }
        }
        // console.log(this.docGranted);
        

    }

    activateControls(){
        if(!this.docGranted) return
        let target = this
        this.controls = new DeviceOrientationControls(target.engine.camera.instance)

        window.addEventListener( "devicemotion", (e) => {
            // do something for 'e' here.
            // console.log('motion update');
            target.controls.update()

            // console.log('devicemotion');

        })

    }


    // useDeviceOrientation(){
    //     if (window.DeviceOrientationEvent) {
    //         window.addEventListener('deviceorientation', function (eventData) {
        
        
    //             var tiltX = Math.round(eventData.gamma * 2 );
        
        
    //             var tiltY =  Math.round(eventData.beta * 2);
        
    //             deviceOrientationHandler(tiltX,tiltY);
        
    //         }, false);
    //     }
        
    //     // function deviceOrientationHandler(tiltX, tiltY){
            
    //     //     // this.mouse.x = tiltX;
    //     //     // console.log(this.mouse.x);
    //     //     // this.mouse.y = tiltY;
    //     // }
    // }

    // deviceOrientationHandler(tiltX,tiltY){
    //     this.mouse.x = tiltX;
    //     this.mouse.y = tiltY
    // }


    update(){

        if(!this.active) return

        // console.log(this.docGranted);
    
        // console.log('orient rotate');
        const range1 = [0, 0.15];
        const range2 = [0.15, 0.6];
        const range3 = [0.6, 0.9];


        let x = Math.abs(this.mouse.x)
        let acceleration = 0

        if (x <= range1[1]) {
            acceleration = 0;
        } else if (x <= range2[1]) {
            // Escala cuadrática ascendente desde range1[1] hasta range2[1]
            let t = (x - range1[1]) / (range2[1] - range1[1]); // Normaliza t a [0, 1] en este rango
            acceleration = t * t;
        } else if (x <= range3[1]) {
            // Escala cuadrática descendente desde range2[1] hasta range3[1]
            let t = (x - range2[1]) / (range3[1] - range2[1]); // Normaliza t a [0, 1] en este rango
            acceleration = (1 - t) * (1 - t);
        } else {
            acceleration = 0;
        }
        

 
        // }

        // console.log(acceleration);

        // let acceleration = (Math.abs(this.mouse.x) * 0.6)
        this.camera.instance.rotation.y += -this.mouse.x * acceleration * 0.01
        this.camera.instance.rotation.x += this.mouse.y * acceleration * 0.01


        // console.log(this.controls);
        // this.controls.update()
        // console.log(this.mouse.x);
        // console.log(0.5 * (1 - Math.cos(Math.PI * this.mouse.x)));
        // console.log(Math.cos(this.mouse.x*Math.PI/2));
        // console.log(1 - Math.cos(Math.PI/2 * this.mouse.x));

        // let sign = this.mouse.x > 0 ? -1 : 1;
        // let borderMin = Math.cos(this.mouse.x*Math.PI/2)
        // let borderMax = 1 - Math.cos(Math.PI/2 * this.mouse.x)

        // // console.log(borderMin*borderMax);

    }
}