import { gsap } from 'gsap';
import EventEmitter from '../Utils/EventEmitter';

export default class AgeGate extends EventEmitter {
    constructor() {
        super();

        this.page = document.getElementById('ageGate');

        this.ageGateIntro = document.querySelector('.age-gate__intro');
        this.introImageNoMobile = document.getElementById('introImgNoMobile');
        this.introImageMobile = document.getElementById('introImgMobile');
        this.ageGateConsole = document.querySelector('.age-gate__console');

        this.continueButton = document.getElementById('ageGateButton');
        this.ageGateMessage = document.getElementById('ageGateMessage');
        
        this.dayInput = document.getElementById('day');
        this.monthInput = document.getElementById('month');
        this.yearInput = document.getElementById('year');
        
        this.initAnimations();

        // Restricciones en la longitud de los inputs
        this.dayInput.addEventListener('input', this.limitInputLength.bind(this, this.dayInput, 2));
        this.monthInput.addEventListener('input', this.limitInputLength.bind(this, this.monthInput, 2));
        this.yearInput.addEventListener('input', this.limitInputLength.bind(this, this.yearInput, 4));

        // Tab inputs
        this.dayInput.addEventListener('keyup', this.tabInput.bind(this));
        this.monthInput.addEventListener('keyup', this.tabInput.bind(this));
        
        this.continueButton.onclick = (event) => {
            event.preventDefault();
            this.verifyAge();
        };
    }

 
    show() {
        this.page.classList.add('section--active');
        // console.log('Show section Age Gate');

        this.playAnimation();
    }

    hide() {
        this.page.classList.remove('section--active');
        // console.log('Hide section Age Gate');
    }

    initAnimations() {
        this.tl = gsap.timeline({
            paused: true,
            defaults: { duration: 1, ease: 'power2.in' },
            onComplete: this.onAnimationComplete.bind(this)
        });

        this.tl
            .to(this.ageGateIntro, {
                duration: 0.85,
                scale: 20,
                delay: 2,
                // yoyo: true,
                // repeat: 1,
                onStart: () => {
                    this.changeImages();
                },
                onComplete: () => {
                    this.ageGateIntro.classList.add('animation-complete');
                }
            })
            .fromTo(this.ageGateConsole, {
                scale: 1.5,
                opacity: 0,
            }, {
                duration: 0.25,
                scale: 1,
                opacity: 1,
            }, '-=0.5')
            .from(this.ageGateConsole.querySelector('.logo'), {
                duration: 0.5,
                ease: 'power2.out',
                opacity: 0,
                y: 10
            }, '-=0.25')
            .from(this.ageGateConsole.querySelector('.advice'), {
                duration: 0.5,
                ease: 'power2.out',
                opacity: 0,
                y: 10
            }, "-=0.2")
            .from(this.ageGateConsole.querySelector('.form__title'), {
                duration: 0.5,
                ease: 'power2.out',
                opacity: 0,
                y: 10
            }, "-=0.2")
            .from(this.ageGateConsole.querySelectorAll('.form__group'), {
                duration: 0.5,
                ease: 'power2.out',
                opacity: 0,
                y: 10,
                stagger: 0.1
            }, "-=0.2")
            .from(this.ageGateConsole.querySelector('.btn'), {
                duration: 0.5,
                ease: 'power2.out',
                opacity: 0,
                y: 10
            }, "-=0.2")
            .from(this.ageGateConsole.querySelector('.policy'), {
                duration: 0.5,
                ease: 'power2.out',
                opacity: 0,
                y: 10
            }, "-=0.2");
    }

    changeImages() {
        // Cambiar las imágenes de desktop y mobile
        this.introImageNoMobile.src = '/images/intro_motion.jpg';
        this.introImageMobile.src = '/images/intro_mobile_motion.jpg';
    }

    onAnimationComplete() {
        // console.log('Animation complete');
    }

    playAnimation() {
        this.tl.play();
    }

    tabInput(e) {
        //console.log(e.target);
      
        if (e.target.value.length == e.target.getAttribute("data-maxlenght")) {
            const form = e.target.form; // Obtén el formulario
            const elements = form.elements; // Obtén todos los elementos del formulario
            const currentIndex = Array.prototype.indexOf.call(elements, e.target); // Índice del elemento actual

            if (currentIndex + 1 < elements.length) {
                elements[currentIndex + 1].focus(); // Mueve el foco al siguiente elemento
            } 
        } 
    }

    limitInputLength(inputElement, maxLength) {
        if (inputElement.value.length > maxLength) {
            inputElement.value = inputElement.value.slice(0, maxLength);
        }
    }

    verifyAge() {
        const day = parseInt(this.dayInput.value, 10);
        const month = parseInt(this.monthInput.value, 10);
        const year = parseInt(this.yearInput.value, 10);


        // Validate that the fields are not empty
        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            this.ageGateMessage.textContent = "Please enter a valid date";
            return;
        }

        // Validate 4-digit year
        if (this.yearInput.value.length !== 4) {
            this.ageGateMessage.textContent = "Please enter a valid year";
            return;
        }

        const yearNumber = parseInt(year, 10);
        const currentYear = new Date().getFullYear();
    
        // Validar que el año esté entre 1900 y el año actual
        if (yearNumber < 1900 || yearNumber > currentYear) {
            this.ageGateMessage.textContent = "Please enter a valid year";
            return;
        }

        // Validate month between 1 and 12
        if (month < 1 || month > 12) {
            this.ageGateMessage.textContent = "Please enter a valid month (1-12)";
            return;
        }

        // Validate day according to month and year
        const daysInMonth = new Date(year, month, 0).getDate();
        if (day < 1 || day > daysInMonth) {
            this.ageGateMessage.textContent = `Please enter a valid day for ${this.getMonthName(month)}`;
            return;
        }

        let fullYear;
        if(year>100){
            fullYear = year
        } else if(year<24) {
            fullYear = 2000+year
        } else{
            fullYear = 1900 + year
        }
        // console.log(fullYear);
        

        const today = new Date();
        const birthDate = new Date(fullYear, month - 1, day);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        // console.log(age);
        
        if (age >= 21) {
            this.ageGateMessage.textContent = "";
            this.trigger('ageOk');
        } else {
            this.ageGateMessage.textContent = "Access denied";
            window.open('https://www.responsibility.org/', '_blank')
            location.reload();

            this.trigger('ageNotOk');
        }
    }

    getMonthName(month) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        return monthNames[month - 1];
    }

}
